<template>
  <div style="height: 44px;display: flex;width: fit-content;color: #333333;font-size: 15px;">
    <div
        style="border-radius: 2px;min-width: 100px;padding: 10px 20px;cursor: pointer;background: #f9f9f9;
        margin-right: 16px;font-size: 12px;line-height: 20px;height: 20px"
        v-for="item in list"
        :class="{active: item.value === checked}"
        :key="item.value"
        @click="$emit('change', item.value)"
    >
      {{ item.label }}
    </div>

  </div>
</template>

<script>
export default {
  name: "HiTab",
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [String, Number]
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>
.active{
  background: rgba(255,0,77,0.10) !important;
  color: rgb(255,0,77);
  font-weight: 600;
}
</style>
