<template>
  <div>
    <el-row type="flex">
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/trends">作品表现</el-breadcrumb-item>
          <el-breadcrumb-item>最新趋势</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-link type="primary" @click="$router.back()">返回</el-link>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <div style="color: #333333;font-size: 25px;font-weight: 500;">近{{ {week: '七日', mon: '一个月', year: '两年'}[id.split('-')[1]] }}趋势图</div>

    <div style="display: flex;justify-content: space-between;margin-top: 40px">
      <hi-tab
          v-model="platformCode"
          :list="[
              {label: 'TME', value: 'tme'},
              {label: 'QQ音乐', value: 'qq'},
              {label: '酷狗', value: 'kg'},
              {label: '酷我', value: 'kw'}
          ]"
          @change="getData"
      ></hi-tab>

<!--      <el-input v-model="search" clearable @change="getData" placeholder="专辑/歌曲名称" prefix-icon="el-icon-search" style="max-width: 275px"></el-input>-->
    </div>

    <div style="background-color: #f8f8f8;height: 142px;margin-top: 30px;display: flex;justify-content: space-between;align-items: center;padding: 20px 30px">
      <div>
        <div style="height: 42px;display: flex;align-items: center">
          <span style="color: #333333;font-size: 16px">{{ queryData.trackName || '' }}</span>
        </div>
        <div style="height: 30px;font-size: 14px">
          <span style="color: #666666">发行时间&nbsp;&nbsp;</span>
          <span style="color: #333333;">{{ $timestampToDate((new Date(queryData.publicTime)) || '') }}</span>
        </div>
        <div style="display: flex;align-items: center;margin-right: 12px">
          <el-image style="width: 40px;height: 40px;margin-right: 12px" :src="queryData.trackCover" v-if="queryData.trackCover"></el-image>
          <div style="font-size: 12px;color: #666666">专辑《{{ queryData.albumName || '' }}》- {{ queryData.singerName || '-' }}</div>
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <div style="min-width: 100px;padding: 0 40px">
          <div style="color: #666666;font-size: 14px">总播放指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 500;margin-top: 8px">{{ (playTotal || 0).toFixed(2) }}</div>
        </div>
        <div style="border-left: 1px rgba(187,187,187,0.5) solid;height: 40px"></div>
        <div style="min-width: 100px;padding: 0 40px">
          <div style="color: #666666;font-size: 14px">总收藏指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 500;margin-top: 8px">{{ (favTotal || 0).toFixed(2) }}</div>
        </div>
        <div style="border-left: 1px rgba(187,187,187,0.5) solid;height: 40px"></div>
        <div style="min-width: 100px;padding: 0 40px">
          <div style="color: #666666;font-size: 14px">总下载指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 500;margin-top: 8px">{{ (downloadTotal || 0).toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div id="chart" style="width: 100%;height: 360px;margin-top: 40px"></div>
  </div>
</template>

<script>
import HiTab from "@/components/HiTab"
import * as echarts from "echarts"

export default {
  name: "trend",
  components: {
    HiTab
  },
  props: {
    id: {
      type: String
    }
  },
  data(){
    return {
      search: '',
      platformCode: 'tme',

      trendData: [],
      chart: null,

      playTotal: 0,
      favTotal: 0,
      downloadTotal: 0,

      queryData: {}
    }
  },
  methods: {
    // foo(item){
    //     let t = item.impDate
    //     let time = t[0] + t[1] + t[2] + t[3] + '-' + t[4] + t[5] + '-' + t[6] + t[7]
    //     time = (new Date(time)).getTime()
    //     console.log(time)
    //     // return time
    //     return time
    // },
    getData(){
      this.chart.showLoading({text: '', color: '#FF004D'})
      this.$post('/api/tmeplatform/songtrend', {
        tmeid: this.id.split('-')[0],
        platform: this.platformCode,
        cycle: this.id.split('-')[1],
        company: this.id.split('-')[2]
      }, {
        baseURL: process.env.VUE_APP_BASE_API_ADMIN
      }).then(r => {
        this.chart.hideLoading()
        if (r.code !== -1){
          this.trendData = r.data.list
          this.playTotal = r.play_total
          this.favTotal = r.fav_total
          this.downloadTotal = r.download_total
          this.option = {
            useUTC: true,
            title: {
              text: '近' + {week: '七日', mon: '一个月', year: '两年'}[this.id.split('-')[1]] + '数据统计',
              textStyle: {
                fontSize: 14,
                fontWeight: 400
              }
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['总播放指数', '总收藏指数', '总下载指数'],
              bottom: 0,
              left: 0
            },
            grid: {
              left: 0,
              right: '4%',
              bottom: '10%',
              containLabel: true
            },
            xAxis: {
              type: 'time',
              boundaryGap: false
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '总播放指数',
                type: 'line',
                color: '#FF2366',
                showSymbol: false,
                data: this.trendData.map(function (item) {
                  const t = item.impDate
                  return [(new Date(t[0] + t[1] + t[2] + t[3] + '-' + t[4] + t[5] + '-' + t[6] + t[7])).getTime(), item.playCnt]
                })
              },
              {
                name: '总收藏指数',
                type: 'line',
                color: '#20A1FF',
                showSymbol: false,
                data: this.trendData.map(function (item) {
                  const t = item.impDate
                  return [(new Date(t[0] + t[1] + t[2] + t[3] + '-' + t[4] + t[5] + '-' + t[6] + t[7])).getTime(), item.favCnt]
                })
              },
              {
                name: '总下载指数',
                type: 'line',
                color: '#33BC83',
                showSymbol: false,
                data: this.trendData.map(function (item) {
                  const t = item.impDate
                  return [(new Date(t[0] + t[1] + t[2] + t[3] + '-' + t[4] + t[5] + '-' + t[6] + t[7])).getTime(), item.downloadCnt]
                })
              }
            ]
          }
          this.chart.setOption(this.option)
        } else {
          this.$message('趋势图加载失败')
        }

      }).catch(e => {
        this.chart.hideLoading()
        console.error(e)
      })
    },
  },
  mounted() {
    this.queryData = JSON.parse(decodeURI(this.$route.query.data || ''))

    this.chart = echarts.init(document.getElementById('chart'))

    this.getData()
  }
}
</script>

<style scoped>

</style>
